/*Experience Section*/
.experience-section {
  align-items: center;

}

.experience-section h1 {
  padding: 40px;
  background-color: #134B70;
  font-size: 40px;
  color: #EEEEEE;
  text-align: center;
}

.experience-body-section {
  display: flex;
  justify-content: space-between;
  padding: 60px;
}

.experience-body-section h2 {
  font-size: 24px;
  font-style: normal;
}

.experience-text {
  max-width: 70%;
}

.experience-text h3 {
  margin-bottom: 15px;
  font-size: 18px;
  font-style: normal;
  line-height: 1.2;
  color: rgb(30 58 138);
  max-width: 70%;
}