@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
}

/*section*/
.section {
    height: 100vh;
    padding: 50px 20px;
    border-bottom: 1px solid #ccc;
  }

  .nav-section {
    background-color: #134B70;
  }

  .text-base{
    color: #EEEEEE;
  }
  
  #aboutme {
    background-color: #EEEEEE;
  }
  
  #experience {
    background-color: #EEEEEE;
  }
  
  #portofolio {
    background-color: #EEEEEE;
  }
  
  #contact {
    background-color: #EEEEEE;
  }