.portofolio-section {
    align-items: center;
  
}
  
.portofolio-section h1 {
    padding: 40px;
    background-color: #134B70;
    font-size: 40px;
    color: #EEEEEE;
    text-align: center;
}