/* Hero-Section */

.hero-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 60px;
}

.hero-text {
    max-width: 50%;
    text-align: center;
}

.hero-text h1 {
    font-size: 48px;
    color: #333;
    line-height: 1.2;
}

.hero-text h2 {
    font-size: 36px;
    color: #333;
    line-height: 1.2;
}

.hero-text h2 span {
    color: #134B70;
    font-weight: 6px;
}

.hero-text p {
    font-size: 18px;
    color: #555;
    margin: 20px 0;
}

.download-btn {
    padding: 10px 20px;
    background-color:#134B70;
    color: #EEEEEE;
    border: none;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;
}